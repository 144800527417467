@import "./src/mixins";
@import "./src/variables";

.header {
    display: flex;
    flex-direction: column; 
    height: 100vh;
    min-height: 100vh;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, $background-color 100%,), url('../../../static/banner.gif');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .container {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        width: 100%;
        max-width: 700px;
    }

    .text {
        display: flex;
        justify-content: space-between;
        margin: 0 30px;
    }

    .buttons {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex-wrap: wrap;
    }

    a {
        border-radius: 10px;
        border: 1px solid $background-color;
        background-color: $primary-color;
        color: $background-color;
        font-size: 18px;
        font-family: "Krona-One";
        padding: 10px 15px;
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        margin-bottom: 16px;

        &:hover {
            background-color: $primary-color-darker;
            cursor: pointer;
        }
    }

    button.secondary {
        border: 1px solid $primary-color;
        color: $primary-color;
        background-color: $background-color;
    }

    p {
        color: $primary-color;
        font-size: 24px;
        font-family: "Krona-One";
    }

    small {
        color: $primary-color;
        font-size: 14px;
        font-family: "Krona-One";
    }
}

// @include breakpoint(sm) {
//     .header {
//         flex-direction: row;
//         margin-bottom: 24px;

//         img {
//             margin: 0;
//         }

//         span {
//             margin: 0 2vw;
//         }
//     }
// }
