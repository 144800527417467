@import "./src/variables";
@import "./src/mixins";

.sponsors {
    margin-bottom: 32px;

    h2 {
        color: $primary-color;
        font-family: "Krona-One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-bottom: 64px;
        padding-left: 2px;
    }

    h3 {
        color: $primary-color;
        font-family: "Krona-One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin-bottom: 64px;
        padding-left: 2px;
    }

}

.sponsorlist {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 16px 32px;
    padding: 0;

    li {
        width: 66%;
        list-style: none;
        justify-content: center;
    }

    .topbar{

        hr {
        margin-bottom: 10px;
        height: 2px;
        width: 100%;
        color: #84C1EE;
        background-color: #84C1EE;
        } 
    }

    .bottombar {

        hr {
            margin-top: 10px;
            height: 2px;
            width: 75%;
            color: #84C1EE;
            background-color: #84C1EE;
        }
    }    

    .img {
        display: flex;
        padding: 8px;

        img {
            width: 100%;
            height: 120px;
            object-fit: contain;
        }
    }

    a {
        text-decoration: none;
    }

    p {
        color: #FFF;
        text-align: center;
        font-family: "Krona-One";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    [data-type="organisation"] > * {
        color: $tu-color;
    }

    [data-type="primary"] > * {
        color: $primary-color;
    }

    [data-type="secondary"] > * {
        color: $secondary-color;
    }
}

@include breakpoint(sm) {
    .sponsorlist li {
        width: 50%;
    }
}

@include breakpoint(md) {
    .sponsorlist li {
        width: 33%;
    }
}

@include breakpoint(xl) {
    .sponsorlist li {
        width: 20%;
    }
}
