@import "./src/variables";
@import "./src/mixins";


.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    gap: 16px;

    .container {
        display: flex;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        gap: 16px;
    }

    p {
        color: $primary-color;
        font-family: "Krona-One";
        font-size: 16px;
        margin: 0;
    }

    a {
        font-family: "Krona-One";
        font-size: 16px;
        text-decoration: none;
        color: #fff;
    }

    .innercontainer {
        display: flex;
        flex-direction: column;
        width: 30%;
    }
}

@include breakpoint(md) {
    .footer .container {
        flex-direction: row;
        gap: 0;
    }
}

