@import "./src/mixins";
@import "./src/variables";

.numbers {
    margin-bottom: 64px;
    // background: linear-gradient(180deg, $background-color 0%, rgba(37, 0, 67, 0.00));

    h2 {
        color: $secondary-color;
        font-family: "Audiowide";
        font-size: 72px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin: 0;
        padding-left: 2px;
    }

    h3 {
        font-family: "Krona-One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin: 0;
        padding-left: 2px;
    }

    .columns {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }
    
    .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 20px;
        justify-content: flex-start;
    }
    

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
            margin: 8px 16px;
            padding: 0.6em 1.6em;
            border-radius: 6px;
            white-space: nowrap;
            text-decoration: none;
            cursor: pointer;

            svg {
                display: inline-block;
                vertical-align: middle;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@include breakpoint(sm) {
    .numbers {
        div {
            flex-direction: row;
        }
    }
}
