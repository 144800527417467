@import "./src/mixins";
@import "./src/variables";

.navigation {
    border: 5px solid $primary-color;
    display: none;



    > div {
        display: flex;
        gap: 16px;
        font-size: 20px;
        font-family: "Krona-One";
        margin: 4px 8px;

        .time {
            margin-left: auto;
            margin-right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .icon {
            color: $secondary-color;
            margin: 0 8px;
            vertical-align: middle;
            display: flex;
        }
    } 
}

@include breakpoint(lg) {
    .navigation {
        display: block;
    }
}