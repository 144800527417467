@import "./src/variables";
@import "./src/mixins";

.faq {
    h2 {
        color: $secondary-color;
        font-family: "Krona-One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin: 0;    }

    ul {
        padding: 0;
        list-style-type: none;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    li {
        margin: 32px 0;
    }

    .icon {
        position: absolute;
        right: 16px;
        top: 16px;
        color: $primary-color;
        font-size: 24px;
    }

    details[open] .icon{
        transform: rotate(180deg);

    }

    details {
        border-radius: 19px;
        border: 3px solid $primary-color;
        padding: 12px 48px;
        position: relative;
    }

    summary {
        cursor: pointer;
        font-size: 1.17em;
        font-weight: 700;
        text-align: center;
        list-style: none;

        &::-webkit-details-marker {
            display: none;
        }
    }

    p {
        margin: 8px 0;
        font-size: 1.17em;
        line-height: normal;
        color: $primary-text;
        text-align: center;
    }
}

@include breakpoint(md) {
    .faq {
        border-radius: 6px;

        .icon {
            top: unset
        }
    }
}
