// Create an array with all photos.
// This is required as the bundler (currently) cannot currently read the contents of the photo folder
export const photosMap: URL[] = [
    new URL("/static/photos/DSC_0001.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0519.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0032.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0511.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0515.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0537.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0544.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0556.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0557.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0558.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0577.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0584.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0598.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0601.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0607.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0614.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0642.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0644.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0646.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0654.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0658.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0662.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0667.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0672.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0685.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0706.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0707.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0781.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0787.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0798.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0806.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0817.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0822.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0860.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0865.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0880.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0904.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0913.jpg?as=webp&width=1200", import.meta.url),
    new URL("/static/photos/DSC_0942.jpg?as=webp&width=1200", import.meta.url),

];
