export { About } from "./about";
export { Committee } from "./committee";
export { FAQ } from "./faq";
export { Footer } from "./footer";
export { Header } from "./header";
export { Icon } from "./icon";
export { Numbers } from "./numbers"
export { Photos } from "./photos";
export { Sponsors } from "./sponsors";
export { Timeline } from "./timeline";
export { Navigation} from "./navigation";
