@import "./src/variables";
@import "./src/mixins";

.committee {
    margin-bottom: 100px;

    h2 {
        color: $primary-color;
        margin-bottom: 0;
        font-size: 38px;
        font-family: "Krona-One";
        margin-bottom: 50px;
        width: 100%;
        text-align: center;

    }

    p {
        margin: 0;
        font-family: "Krona-One";
        font-size: 16px;
    }

    .name {
        margin: 0;
        font-family: "Krona-One";
        font-size: 16px;
    }

    .function {
        color: $primary-color;
    }

    .container {
        flex-direction: column;
        flex-wrap: nowrap;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .left, .right {
        gap: 24px;
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: left;
    }

    .right {
        margin-top: 24px;
    }

    .right .person{
        flex-direction: row-reverse;
        justify-content: flex-end;
    }

    
    .middle {
        width: 100%;
        margin-top: 24px;

        .person {
            flex-direction: row;
            gap: 20px;
        }
    }

    img {
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        height: 100px;
        width: 90px;
        min-width: 90px;
        object-fit: cover;
        position: relative;
    }

    .polygon {
        height: 110px;
        width: 100px;
        min-width: 100px;
        clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
        background-color: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .person {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

@include breakpoint(md) {
    .committee {
        h2 {
            font-size: 48px;
        }

        .container {
            flex-direction: row;
            flex-wrap: wrap;
        }

        .left {
            width: 50%;
        }
    
        .right {
            width: 50%;
            text-align: right;
            margin-top: 0;
    
            .person {
                justify-content: flex-end;
                flex-direction: row;
            }
        }
    
        .middle {
            width: 100%;
            text-align: center;
            margin-top: 24px;
    
            .person {
                justify-content: center;
                flex-direction: column;
                align-items: center;
                gap: 20px;
            }
        }
    }
}

