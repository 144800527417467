@import "./variables";
@import "./mixins";

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

body {
    display: flex;
    flex-direction: column;
    color: $primary-text;
    background-image: url(../static/Background.png);
    background-repeat: repeat-y repeat-x;
    font-family: Helvetica, sans-serif;
    line-height: 1.5;
    font-size: 1.1em;
    position: relative;
}

.gradient {
    background: linear-gradient(180deg, $background-color 25%, rgba(0, 0, 0, 0.00) 100%);
}

section {
    margin: 0 8px;
}

@include breakpoint(sm) {
    section {
        margin: 0 80px;
    }
}

// @include breakpoint(sm) {
//     body {
//         width: 80%;
//         margin: 0 10%;
//     }
// }

// @include breakpoint(md) {
//     body {
//         width: 70%;
//         margin: 0 15%;
//     }
// }

// @include breakpoint(lg) {
//     body {
//         width: 60%;
//         margin: 0 20%;
//     }
// }

@font-face {
    font-family: "Krona-One";
    src: url(../static/fonts/KronaOne-Regular.ttf);
}

@font-face {
    font-family: "Audiowide";
    src: url(../static/fonts/Audiowide-Regular.ttf);
}
