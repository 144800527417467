@import "./src/mixins";
@import "./src/variables";

.about {
    margin-bottom: 32px; 


    h2 {
        color: $primary-color;
        font-family: "Krona-One";
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin: 0;
        padding-left: 2px;
    }

    h3 {
        font-family: "Krona-One";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        margin: 0;
        padding-left: 2px;
    }

    .columns {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    
    .column {
        flex: 1;
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 20px;
        justify-content: flex-start;
    }
    

    p {
        margin: 8px;
        margin-bottom: 50px;

        font-family: "Krona-One";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a {
            color: $primary-color;
        }
    }

    div {
        display: flex;
        flex-direction: column;
        justify-content: center;

        a {
            margin: 8px 16px;
            padding: 0.6em 1.6em;
            border-radius: 6px;
            white-space: nowrap;
            text-decoration: none;
            cursor: pointer;

            svg {
                display: inline-block;
                vertical-align: middle;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.tickets {
    color: $primary-text;
    background-color: $primary-color;
}

.discord {
    color: $primary-text;
    background-color: $discord-color;
}

@include breakpoint(sm) {
    .about {
        div {
            flex-direction: row;
        }
    }
}
